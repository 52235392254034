import { Cover } from "./styles";
const Footer = ({tapa}) => {
  return (
    <Cover>
      { (
        <div className="cover-footer">
          <p>Edición impresa</p>
          <div className="centered">
            {tapa?.url && <a href={`/tapa-diario-clarin-hoy.html`}>
               <picture>
                <img
                  width="100"
                  height="100"
                  src={`https://www.clarin.com/img/${tapa?.url}`}
                  loading="lazy"
                  alt="Tapa diario"
                />
              </picture>
            </a>}
            <a
              className="button"
              aria-label="Tapa diario clarin"
              href={`/tapa-diario-clarin-hoy.html`}
            >
              Leé el diario de hoy
            </a>
          </div>
        </div>
      )}
    </Cover>
  );
};

export default Footer;
